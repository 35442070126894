.footer {
    width: 100%;
    min-height: 50px;
    padding: 20px 0px;
    background-color: #1f2229;
    margin: 0 auto;
}

.footer-content {
    padding-top: 20px;
    color: #a7b0c3;
    display: flex;
    margin: 0 auto;
    user-select: none;
    justify-content: space-between;
}

.footer-column {
    display: inline-flex;
}

.footer-content a {
    text-decoration: none;
    color: #a7b0c3;
}

.wave {
    fill: #1f2229;
}

.footer-title {
    font-family: "Fredoka One", cursive;
    font-size: 22px;
}

@media only screen and (max-width: 1000px) {
    .wave {
        display: none;
    }

    .footer-column {
        display: block;
        padding-bottom: 35px;
    }

    .footer-column img {
        padding-bottom: 20px;
    }

    .footer-content {
        display: block;
    }
}