.summary
    display: flex
    border-radius: 10px
    width: 100%
    margin-bottom: 5px

.big,
.fullwide,
.fullmedium,
.wide,
.normal
    border-radius: 10px
    margin: 3px 3px
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    border: 2px solid #313742
    transition: all .2s ease-in-out
    cursor: pointer

.big:hover,
.fullwide:hover,
.fullmedium:hover,
.wide:hover,
.normal:hover
    transition: all .2s ease-in-out
    transform: scale(1.05)
    border: 2px solid #FFC44D
    box-shadow: 0 5px 8px -5px rgb(0 0 0 / 30%), 0 5px 30px -5px rgb(0 0 0 / 50%)

.big
    width: 48.5%

.fullwide
    width: 100%
    height: 200px

.fullmedium
    width: 100%
    height: 105px

.wide
    width: 50%
    height: 200px

.normal
    width: 33%
    height: 200px

.big
    width: 50%
    height: 400px

.subsummary-big
    width: 50%

.content
    border-radius: 10px
    width: calc(100%)
    height: calc(100%)
    background: linear-gradient(to bottom, rgba(12,34,51,0.1) 0%, rgba(12,34,51,0.2) 30%, rgba(12,34,51,0.7))
    padding: 20px
    display: flex
    flex-direction: column
    justify-content: flex-end

.title
    font-size: 28px
    font-weight: bold
    color: white
    display: block

.subtitle
    display: block
    color: white