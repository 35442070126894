.post {
    background-color: #2d313a;
    color: white;
    border-radius: 10px;
}

.post .header {
    background-color: #313742;
    padding: 15px 40px;
    border-radius: 10px 10px 0px 0px; 
    user-select: none;  
}

.post .header .link {
    color: #ffc44d;
}

.post .banner {
    height: 150px;
    background-position: center;
}

.post .banner .banner-content {
    background: linear-gradient(to bottom, rgba(12,34,51,0.7) 0%, rgba(12,34,51,0.5) 30%, rgba(12,34,51,0.7));
    width: 100%;
    height: 100%;
    padding: 20px;
}

.post .banner .banner-content .post-title {
    font-size: 45px;
    font-weight: 700;
    margin: 20px;
}

.post .banner .banner-content .post-author {
    font-size: 18px;
    margin: 20px;
}

@media only screen and (max-width: 800px) {
    .post .banner {
        height: 120px;
    }

    .post .banner .banner-content .post-title {
        font-size: 30px;
        margin: 15px;
    }
    
    .post .banner .banner-content .post-author {
        font-size: 13px;
        margin: 15px;
    }
}

@media only screen and (max-width: 360px) {
    .post .banner {
        height: 100px;
    }

    .post .banner .banner-content .post-title {
        font-size: 27px;
        margin: 12px;
    }
    
    .post .banner .banner-content .post-author {
        font-size: 11px;
        margin: 12px;
    }
}

.post .banner .banner-content .post-author a {
    text-decoration: underline;
}

.post .post-content {
    margin: 30px;
    padding-bottom: 20px;
}

.post .post-content p {
    padding-bottom: 10px;
}

.post .post-content a {
    color: #ff7d79
}

.post .post-content ul {
    margin-left: 20px;
    padding-bottom: 10px;
}

.post .post-content hr {
    color: #a7b0c3;
    margin: 15px 0px;
}

.post .post-content h1 {
    font-size: 38px;
}

.post .post-content h2 {
    font-size: 31px;
}

.post .post-content h3 {
    font-size: 24px;
}

.post .post-content img {
    margin: 0 auto;
}