.login-form {
    display: inline-block;
    width: 100%;
    margin: 30px 0px;
}

.login-form p {
    padding-bottom: 20px;
}

.login-form p input {
    background: #444c5c;
    padding: 10px;
    width: 60%;
    border-radius: 7px;
}

.login-form p button {
    width: 30%;
}

@media only screen and (max-width: 900px) {
    .login-form p input {
        width: 70%;
    }
}

@media only screen and (max-width: 600px) {
    .login-form p input {
        width: 80%;
    }

    .login-form p button {
        width: 40%;
    }
}

@media only screen and (max-width: 400px) {
    .login-form p button {
        width: 50%;
    }
}

.error {
    user-select: none;
    padding: 10px;
    margin: 10px;
    background: rgb(197, 92, 92);    
    border-radius: 7px;
    color: #ffffff
}

.success {
    user-select: none;
    padding: 10px;
    margin: 10px;
    background: rgb(74, 156, 67);    
    border-radius: 7px;
    color: #ffffff
}