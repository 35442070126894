.hero
    width: 100%
    text-align: center
    padding: 30px 0px

.logo
    width: 120px
    display: block
    margin: auto

@media only screen and (max-width: 800px)
  .logo
    width: 90px
