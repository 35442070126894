.cont {
    background-color: #2d313a;
    color: white;
    border-radius: 10px;
    margin-bottom: 30px;
}

.cont .header {
    background-color: #313742;
    padding: 15px 40px;
    border-radius: 10px 10px 0px 0px; 
    user-select: none;  
}

.cont .header a {
    color: #ffc44d;
}

.cont .cont-content {
    margin-top: 10px;
    padding: 10px;
}