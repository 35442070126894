.navbar
    border-radius: 14px
    color: #a7b0c3
    background: #313742
    position: sticky

.navbar-content
    padding: 0px 0px 0px 30px
    display: inline-flex
    width: calc(100% - 30px)

.item-list
    display: inline-flex
    align-items: center

.hamburger-menu
    display: none

@media only screen and (max-width: 1100px)
  .hamburger-menu
    display: inline-flex

  .item
    display: none

  .item-selected
    display: none

  .navbar-content
    padding: 0px 0px 0px 10px
  
  .dropdown-content
    left: 0
    margin-top: 50px
    z-index: 5
    width: 100%

.item-list-right
    margin-left: auto
    display: inline-flex
    align-items: center

.hamburger-menu,
.item-selected,
.item
    padding: 15px 20px
    transition: ease-in-out 0.2s all

.item:hover
    background-color: #a7b0c3
    cursor: pointer

.hamburger-menu > span,
.item-selected > span,
.item > span
    color: #a7b0c3
    text-decoration: none
    font-size: 18px
    transition: ease-in-out 0.2s all
    font-family: 'Fredoka One', cursive

.item:hover > span
    color: #313742

.hamburger-menu:hover,
.item-selected
    background-color: #ff4842

.hamburger-menu:hover > span,
.item-selected > span
    color: white

.item-selected > .dropdown-content,
.hamburger-menu > .dropdown-content,
.item > .dropdown-content
    display: none
    position: absolute
    background-color: #313742
    min-width: 160px
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2)
    z-index: 5

.item > .dropdown-content
    margin-top: 15px

.hamburger-menu > .dropdown-content
    margin-top: 50px
    border-radius: 10px

.hamburger-menu:hover .dropdown-content,
.item:hover .dropdown-content,
.item-selected:hover .dropdown-content
    display: block

.avatar
    width: 24px
    margin-right: 10px

.basket-button
    transition: ease-in-out 0.2s all

.basket-button:hover
    color: white

.dropdown-content > .item
  display: block

.login-button
  display: none

@media only screen and (max-width: 1250px)
  .login-button
    display: inline-flex !important
